@use 'sass:map';
@use '@angular/material' as mat;
@import "./mytheme.scss";
@import "./margin.scss";
@import "../src/assets/fontawesome_V6.5.1/css/fontawesome.min.css";
@import "../src/assets/fontawesome_V6.5.1/css/solid.min.css";
@import "../src/assets/fontawesome_V6.5.1/css/brands.min.css";
@import "../src/assets/fontawesome_V6.5.1/css/duotone.min.css";

body {
  margin: 0;
  font-family: Roboto, "Nunito", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  color: mat.get-theme-color($algo-app-theme, primary, 500);
  background-color: mat.get-theme-color($algo-app-theme, primary, A100);
}

.background-secondary {
  background-color: mat.get-theme-color($algo-app-theme, primary, 800) !important;
}

.color-secondary {
  color: mat.get-theme-color($algo-app-theme, primary, A100) !important;
}

.border-color-accent {
  border-color: mat.get-theme-color($algo-app-theme, accent, 50) !important;
}

.border-color-warn {
  border-color: mat.get-theme-color($algo-app-theme, warn, 50) !important;
}

.example-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 64px;
  bottom: 0;
  left: 0;
  right: 0;
}

@media only screen and (max-width: 600px) {
  .example-container {
    top: 56px;
  }
}

.example-is-mobile .example-toolbar {
  position: fixed;
  /* Make sure the toolbar will stay on top of the content as it scrolls past. */
  z-index: 2;
}

h1.example-app-name {
  margin-left: 8px;
}

.example-card {
  max-width: 400px;
}

.example-header-image {
  background-size: cover;
}

.mat-mdc-progress-spinner {
  position: absolute;
  top: 45%;
  left: 45%;
}