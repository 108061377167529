/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@include mat.core();

$algo-palette: (
    //warn
    50: #f5bb00,
    //error
    100: #d34a4aff,
    //logo-green & success 
    200: #3ca085,
    //info 
    300: #36b9cc,
    //gray-light
    400: #dfd9d9,
    // gray
    500: #858796,
    // white-gray
    600: #dfd9d9,
    // dark
    700: #5a5c69,
     //gray-dark
    800: #404040,
    900: #808080,
    //white
    A100: #fff,
    // rest unbelegt
    A200: #105945,
    A400: #136751,
    A700: #15765C,
    contrast: (50: #fff,
        100: #fff,
        200: #fff,
        300: #fff,
        400: #fff,
        500: #fff,
        600: #858796,
        700: #858796,
        800: #858796,
        900: #858796,
        A100: #858796,
        A200: #858796,
        A400: #858796,
        A700: #fff,
    )
);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$algo-app-primary: mat.define-palette($algo-palette, 200);
$algo-app-accent: mat.define-palette($algo-palette, 50);
$algo-app-warn: mat.define-palette($algo-palette, 100);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as `color` or `typography`.
$algo-app-theme: mat.define-light-theme((color: (primary: $algo-app-primary,
                accent: $algo-app-accent,
                warn: $algo-app-warn )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($algo-app-theme);
/*body.light-theme {
  @include mat.all-component-themes($candy-app-theme);
}*/
/*$light-primary: mat.define-palette($wfs-blue-palette);

$light-accent: mat.define-palette($wfs-blue-palette, 200, 100, 400);

$light-theme: mat.define-palette(
  (
    color: (
      primary: $light-primary,
      accent: $light-accent,
    ),
  )
);

// define the dark theme palettes
$dark-primary: mat.define-palette(mat.$blue-gray-palette);
$dark-accent: mat.define-palette(mat.$amber-palette, A200, A100, A400);

$dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $dark-primary,
      accent: $dark-accent,
    ),
  )
);

body.light-theme {
  @include mat.all-component-themes($light-theme);
}

body.dark-theme {
  @include mat.all-component-themes($dark-theme);
}*/